import { DialogController }                 from 'aurelia-dialog';
import { bindable, inject }                 from 'aurelia-framework';
import { PLATFORM }                         from 'aurelia-pal';
import { BaseFormViewModel }                from 'base-form-view-model';
import { SpecificationsRepository }         from 'modules/management/specifications/specifications/services/repository';
import { SpecificationRevisionFormSchema }  from 'modules/management/specifications/specifications/specification-revisions/form-schema';
import { SpecificationRevisionsRepository } from 'modules/management/specifications/specifications/specification-revisions/services/repository';
import { AppContainer }                     from 'resources/services/app-container';

@inject(AppContainer, DialogController, SpecificationsRepository, SpecificationRevisionsRepository, SpecificationRevisionFormSchema)
export class CreateSpecificationRevisionModal extends BaseFormViewModel {

    formId = 'management.specifications.specifications.revisions.creation-form';

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    specificationId;

    @bindable settings = {
        title:   'form.title.create-new-record',
        size:    'modal-xl',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param specificationsRepository
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, dialogController, specificationsRepository, repository, formSchema) {
        super(appContainer);

        this.dialogController         = dialogController;
        this.specificationsRepository = specificationsRepository;
        this.repository               = repository;
        this.formSchema               = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/specifications/specifications/specification-revisions/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.specifications.manage',
            'management.specifications.specifications.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.specificationId = params.specification.id;
        this.nextSpecificationRevisionNumber = await this.fetchNextSpecificationRevisionNumber();
        this.lastSpecificationEditionNumber = await this.fetchLastSpecificationEditionNumber();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model(this.nextSpecificationRevisionNumber, this.lastSpecificationEditionNumber);
        this.model        = this.formSchema.model(this.nextSpecificationRevisionNumber, this.lastSpecificationEditionNumber);
    }

    /**
     * Fetches last specification revision number
     *
     * @return {Promise<*>}
     */
    async fetchNextSpecificationRevisionNumber() {
        return await this.specificationsRepository.nextSpecificationRevisionNumber(this.specificationId);
    }

    /**
     * Fetches last specification edition number
     *
     * @return {Promise<*>}
     */
    async fetchLastSpecificationEditionNumber() {
        return await this.specificationsRepository.lastSpecificationEditionNumber(this.specificationId);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.specificationId, this.model)
            .then((response) => {
                this.handleResponse(response);
                this.initialModel.assign(this.model);

                if (response.status === true) {
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

}
