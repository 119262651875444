import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class SpecificationsRepository extends BaseRepository {
    baseUrl = 'management/specifications/specifications';

    /**
     * Fetches the last specification revision number
     *
     * @param specificationId
     * @return {*}
     */
    nextSpecificationRevisionNumber(specificationId) {
        return this.httpClient.get(`${this.baseUrl}/${specificationId}/next-revision-number`);
    }

    /**
     * Fetches the last specification edition number
     *
     * @param specificationId
     * @return {*}
     */
    lastSpecificationEditionNumber(specificationId) {
        return this.httpClient.get(`${this.baseUrl}/${specificationId}/last-edition-number`);
    }

    /**
     * Duplicates a record
     *
     * @param duplicatingId
     * @param data
     *
     * @returns {*}
     */
    duplicate(duplicatingId, data) {
        return this.httpClient.post(`${this.baseUrl}/${duplicatingId}/duplicate`, data);
    }
}
